<template>
  <div>
    <div class="card-toolbar mb-5">

      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('extension_requests.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('extension_requests.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{ $t('search') }}</span>
                                </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{ $t('reset_search') }}</span>
                                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="status" slot-scope="props">
            <!--            v-if="$can('partners.update')"-->
            <button  @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{$t('change_status')}} ({{ props.row.status_name }})
            </button>
          </template>

          <template slot="actions" slot-scope="props">
            <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('more_actions') " variant="outline-primary" class="m-2">
              <a href="javascript:;" class="dropdown-item" @click="showModalReasons(props.row.reasons)">
                {{ $t('extension_requests.reasons') }}
              </a>
            </b-dropdown>
          </template>
        </v-server-table>
        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="reasons-modal" size="md" hide-footer :title="$t('extension_requests.reasons')">
      <div>
        <div class="form-group row">
          <div class="col-lg-12 mb-5">
            <p>{{ reasons }}</p>
          </div>
        </div>
        <div class="card-footer pl-0 pr-0 pb-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="reset" class="btn btn-secondary" @click="hideModalReasons">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";
export default {
  components: {'change-status-form': ChangeStatusFrom},
  data() {
    return {
      mainRoute: '/subscription/extension_requests',
      subMainRoute: '/subscription/extension_request',
      routeChangeStatus: '/subscription/extension_request/change-status',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,

      filters: {
        from_date: null,
        to_date: null,
      },

      status_list: [],

      columns: [
        'user_name',
        'business_name_subdomain',
        'business_name',
        'plan_name',
        'registration_date',
        'expire_date',
        'last_amount_paid',
        'request_date',
        'days_number',
        'status',
        'actions'
      ],

      validation: [],
      dataList: [],
      reasons: null,

      innerId: null,
      statusId: null,

    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          user_name: that.$t('extension_requests.user_name'),
          business_name_subdomain: that.$t('extension_requests.business_name_subdomain'),
          business_name: that.$t('extension_requests.business_name'),
          plan_name: that.$t('extension_requests.plan_name'),
          registration_date: that.$t('extension_requests.registration_date'),
          expire_date: that.$t('extension_requests.expire_date'),
          last_amount_paid: that.$t('extension_requests.last_amount_paid'),
          request_date: that.$t('extension_requests.request_date'),
          days_number: that.$t('extension_requests.days_number'),
          status: that.$t('status'),
          actions: that.$t('actions'),

        },
        sortable: ['created_at'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params}).catch(function (error) {
            console.error(error)
          })

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  // watch: {
  //     user: function (val) {
  //         if (val) {
  //             this.filters.user_id = val.id;
  //         } else {
  //             this.filters.user_id = null;
  //         }
  //     },
  // },
  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.extension_requests")}]);
    this.getStatusList();
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;

      this.$refs.table.refresh();
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency+"/extension_requests_status").then((response) => {
        this.status_list = response.data.data;
      });
    },



    showModalReasons(reason) {
      this.reasons = reason;
      this.$refs['reasons-modal'].show()
    },
    hideModalReasons() {
      this.reasons = null;
      this.$refs['reasons-modal'].hide();
    },



    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
  },
};
</script>
